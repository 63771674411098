@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');

body{
  margin:0 33% !important;
  font-family: Sen !important;
  min-height: 100vh;

}

.out-link{
  color:whitesmoke;
}

/*Desktop View*/
/* Form Container */
.form-container{
  display: flex;
  border-radius: 0 !important;
  box-shadow:  none !important;
  flex-direction:column;
  width: 500px;
  height: 100vh;
  align-items: center !important;
  justify-content: center !important;
  background-image: linear-gradient(70deg, rgba(40, 40, 50, 0.4), rgba(40, 40, 80,0.4)), url(./images/cl-1.JPG) !important;
  background-position: center !important;
  background-size: cover!important;
  background-repeat: no-repeat !important;
}

.form-content{
  min-width: 450px;
}

.ui .input input {
  font-family: Sen !important;
}

.ui, .menu,  .item,  .header {
  font-family:Sen !important;
}

.ui .table{
  background-color: #fff !important;
}

.ui > .menu > .item {
  font-family:Sen !important;
}

.ui .li {
  font-family: Sen !important;
}



@media screen and (max-width:800px){
  body{
    margin:0 !important; 
  }
  
  .container{
    min-height:100vh !important;
    width:100% !important;
    margin:0px !important;
  }

  .bottom-tabs{
    display:block !important;
    padding:5px !important;
  }

  .bottom-ps{
    padding-top:2px;
    text-align:center;
    font-size:13px !important;
  }

  .inner-nodes{
    padding-bottom:50px  !important;
  }

  .white{
    background-color: '#fff' !important;
  }

  .contain{
    object-fit: scale-down !important;
    width: 100px !important;
    height: 100px !important;
  }
  .details-modal{
    min-height: 70vh;
    max-width:100%;
  }

  .details{
    margin:20px !important;
    max-width:85%;
  }

  .details > .dt{
    padding-top:15px;
  }

  .headers{
    padding-top: 69px !important; 
    padding-left: 20px !important;
  }

  .nms{
    display:flex !important;
    flex-direction: row !important;
  }

  .padded{
    margin: 0 10px !important; 
  }

  .form-container{
    display:flex !important;
    width:100% !important;
    height: 100vh !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    background-color:rgba(79, 88, 71, 0.699) !important;
  }

  .form-content{
    min-width: 300px;
  }

 



 




}